import React from 'react';
import { graphql } from 'gatsby';

import PrizeGiveawayBody from '@/components/prize-giveaway/prize-giveaway-body';
import SEO from '@/components/seo';

const databricksDataAiSummit2024PrizeGiveaway = ({ data }) => {
  return <PrizeGiveawayBody data={data.sanityDatabricksAiSummit} />;
};

export const query = graphql`
  query sanityDatabricksAiSummit {
    sanityDatabricksAiSummit {
      seo {
        metaTitle
      }
      title
      mainImage {
        asset {
          url
          gatsbyImageData
        }
      }
      textBelowImage
      hubspotForm {
        hubspotPortalId
        hubspotFormId
        hubspotFormVideoUrl
        redirectToThankYouPage
      }
      _rawTermsAndConditions(resolveReferences: { maxDepth: 10 })
    }
  }
`;

export default databricksDataAiSummit2024PrizeGiveaway;

export const Head = ({ data }) => {
  const { seo } = data.sanityDatabricksAiSummit;
  return (
    <>
      <meta name="robots" content="noindex" />
      <SEO title={seo.metaTitle} />
    </>
  );
};
